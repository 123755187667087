import React, { useMemo } from 'react';
import {
  Stack,
  Typography,
  Box,
  IconButton,
  LinearProgress,
  Fade,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useCreateTodo, useDeleteTodo, useUpdateTodo } from '@legalsurf/hooks';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';
import TodoRow from './TodoRow';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

const CheckListSection = ({ event }) => {
  const studioId = useStudioId();

  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CALENDAR.UPDATE,
  ]);

  const areAllChecked = useMemo(
    () => event.todos.length > 0 && event.todos.every((todo) => todo.done),
    [event.todos],
  );

  const [createTodo, { loading: isCreating }] = useCreateTodo();
  const [deleteTodo, { loading: isDeleting }] = useDeleteTodo();
  const [updateTodo, { loading: isUpdating }] = useUpdateTodo();

  if (!event.todos.length) {
    return (
      <CreationInfoBanner
        buttonStr="Cargar subtarea"
        description="Crear una lista de subtareas y organiza tus procesos."
        title="Lista de tareas"
        onClick={
          hasPermissions
            ? () =>
                createTodo({
                  variables: {
                    studioId,
                    eventId: event.id,
                    calendarId: event.calendarId,
                    title: '',
                  },
                })
            : undefined
        }
      />
    );
  }

  return (
    <Stack
      sx={{
        p: 2,
        borderRadius: 1,
        position: 'relative',
        backgroundColor: areAllChecked ? 'success.200' : 'primary.100',
        overflow: 'hidden',

        ...(!event.todos.length
          ? {
              borderStyle: 'dashed',
              borderWidth: 2,
              borderColor: 'primary.300',
              transition: 'all 100ms linear',
              ':hover': {
                borderColor: 'primary.main',
                backgroundColor: 'primary.300',
              },
            }
          : undefined),
      }}
    >
      <Fade in={isUpdating || isCreating || isDeleting}>
        <LinearProgress
          color={areAllChecked ? 'success' : 'primary'}
          sx={{
            borderRadius: 0.5,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      </Fade>

      {!!event.todos.length && (
        <Stack spacing={0.5}>
          <Typography fontSize={16} fontWeight={600}>
            Lista de tareas
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {event.todos.map((todo, index) => (
              <TodoRow
                key={todo.id}
                onBlur={(newTodo) =>
                  updateTodo({
                    variables: {
                      studioId,
                      calendarId: event.calendarId,
                      ...newTodo,
                    },
                  })
                }
                onDelete={(e) => {
                  e.stopPropagation();

                  deleteTodo({
                    variables: {
                      calendarId: event.calendarId,
                      studioId,
                      id: todo.id,
                    },
                  });
                }}
                {...todo}
                title={todo.title || `Tarea #${index + 1}`}
              />
            ))}
          </Box>
        </Stack>
      )}

      <Stack spacing={0.5}>
        {event.todos.length > 0 && (
          <Box pl={1}>
            <IconButton
              disabled={isCreating || !hasPermissions}
              size="small"
              sx={{ borderRadius: '1px', padding: 0.2 }}
              onClick={() =>
                createTodo({
                  variables: {
                    studioId,
                    eventId: event.id,
                    calendarId: event.calendarId,
                    title: '',
                  },
                })
              }
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Box>
        )}

        {!event.todos.length && (
          <CreationInfoBanner
            buttonStr="Cargar subtarea"
            description="Crear una lista de subtareas y organiza tus procesos."
            title="Lista de subtareas"
            onClick={
              hasPermissions
                ? () =>
                    createTodo({
                      variables: {
                        studioId,
                        eventId: event.id,
                        calendarId: event.calendarId,
                        title: '',
                      },
                    })
                : undefined
            }
          />
        )}
      </Stack>
    </Stack>
  );
};

export default CheckListSection;
