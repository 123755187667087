import React, { useState } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Edit } from '@mui/icons-material';

const DocumentCell = ({ row: file }) => {
  const apiRef = useGridApiContext();
  const [showEditButton, setShowEditButton] = useState(false);

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      onMouseEnter={() => setShowEditButton(true)}
      onMouseLeave={() => setShowEditButton(false)}
    >
      <Typography
        noWrap
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
          '&:hover': {
            color: 'primary.main',
          },
          maxWidth: 350,
        }}
      >
        {file.name}
      </Typography>

      <IconButton
        sx={{
          opacity: showEditButton ? 1 : 0,
          transition: 'opacity 0.2s',
          // Prevent the button from taking space when hidden
          visibility: showEditButton ? 'visible' : 'hidden',
        }}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          apiRef.current.startCellEditMode({ id: file.id, field: 'name' });
        }}
      >
        <Edit />
      </IconButton>
    </Stack>
  );
};

export default DocumentCell;