export const DEFAULT_PAYMENT_METHODS = [
  {
    value: 'CASH',
    label: 'Efectivo',
  },
  {
    value: 'CARD',
    label: 'Tarjeta',
  },
  {
    value: 'TRANSFER',
    label: 'Transferencia',
  },
  {
    value: 'OTHER',
    label: 'Otro',
  },
];

export const DEFAULT_TASK_STATES = [
  {
    value: 'pending',
    label: 'Pendiente',
    color: '#FFFFFF',
    bg: '#FFDE6A',
  },
  {
    value: 'wip',
    label: 'En progreso',
    color: '#dc5270',
    bg: '#FFE9E6',
  },
  {
    value: 'done',
    label: 'Realizado',
    color: '#FFFFFF',
    bg: '#74C9BB',
  },
  {
    value: 'reviewing',
    label: 'En revision',
    color: '#E5E7FE',
    bg: '#6E40E1',
  },
  {
    value: 'finished',
    label: 'Archivado',
    color: '#929292',
    bg: '#F0F2F5',
  },
];

export const tasksStatesObj = DEFAULT_TASK_STATES.reduce(
  (acc, val) => ({
    ...acc,
    [val.value]: val,
  }),
  {},
);

export const paymentMethodsObj = {
  cash: 'Efectivo',
  card: 'Tarjeta',
  transfer: 'Transferencia',
  other: 'Otro',
};

export const LS_PERMISSIONS_MAP = {
  FILECASES: {
    CREATE: 'create:filecases_single',
    UPDATE: 'update:filecases_single',
    DELETE: 'delete:filecases_single',
    ACCESS: 'read:filecases_module',
    // Deprecate read:filecase_single
    READ: 'read:filecases_single',
  },
  DIRECTORY: {
    CREATE: 'create:directory_single',
    UPDATE: 'update:directory_single',
    DELETE: 'delete:directory_single',
    ACCESS: 'read:directory_module',
    READ: 'read:directory_single',
  },
  SALES: {
    CREATE: 'create:sales_entry',
    UPDATE: 'update:sales_entry',
    DELETE: 'delete:sales_entry',
    ACCESS: 'read:sales_module',
    READ: 'read:sales_entry',
  },
  EXPENSES: {
    CREATE: 'create:expenses_entry',
    UPDATE: 'update:expenses_entry',
    DELETE: 'delete:expenses_entry',
    ACCESS: 'read:expenses_module',
    READ: 'read:expenses_entry',
  },
  CALENDAR: {
    CREATE: 'create:calendar_entry',
    UPDATE: 'update:calendar_entry',
    DELETE: 'delete:calendar_entry',
    ACCESS: 'read:calendar_module',
  },
  REPORTS: {
    ACCESS_FILECASES: 'read:reports_filecases_module',
    ACCESS_TREASURY: 'read:reports_treasury_module',
    ACCESS: 'read:reports_module', // Deprecated
  },
  CONFIG: {
    ACCESS: 'read:config_module',
    ACCESS_ACCOUNT: 'read:config_ls_account', // Deprecated
    ACCESS_ACCOUNTS_AND_PERMISSIONS: 'read:accounts_and_permissions_module',
    ACCESS_STUDIO_DATA: 'read:config_studio_data',
    ACCESS_CATEGORIES: 'read:config_categories',
    ACCESS_NOTIFICATION_TEMPLATES: 'read:config_notification_templates',
  },
  DOCUMENTS: {
    ACCESS: 'read:documents_module',
    CREATE: 'create:document_entry',
    UPDATE: 'update:document_entry',
    DELETE: 'delete:document_entry',
  },
  // maldita deuda, falta el crud
  TIME_TRACKING: {
    ACCESS: 'read:time_tracking_module',
  },
  CUSTOM_FIELDS: {
    ACCESS: 'read:custom_fields'
  },
  CONTRACT_TEMPLATES: {
    ACCESS: 'read:contract_templates'
  },
  WORKFLOWS: {
    ACCESS: 'read:workflows'
  },
} as const;

export const entityTypes = [
  { value: 'customer', label: 'Cliente' },
  { value: 'person', label: 'Persona' },
  { value: 'company', label: 'Empresa' },
  { value: 'contrary', label: 'Contrario' },
  { value: 'ownLawyer', label: 'Abogado Propio' },
  {
    value: 'contraryLawyer',
    label: 'Abogado Contrario',
  },
  { value: 'ownProcurator', label: 'Procurador Propio' },
  {
    value: 'contraryProcurator',
    label: 'Procurador Contrario',
  },
  { value: 'notary', label: 'Notario' },
  { value: 'judge', label: 'Juez' },
  { value: 'court', label: 'Corte' },
  { value: 'place', label: 'Lugar' },
  { value: 'provider', label: 'Proveedor' },
  { value: 'prospect', label: 'Prospecto' },
  { value: 'tribunal', label: 'Juzgado' },
  { value: 'prosecution', label: 'Fiscalía' },
  { value: 'fiscal', label: 'Fiscal' },

  // New types for alba (client)
  { value: 'tribunal2', label: 'Tribunal' },
  { value: 'taker', label: 'Tomador' },
  { value: 'guarantor', label: 'Avalista' },
  { value: 'lawyer', label: 'Abogado' },
  { value: 'insured', label: 'Asegurado' },
  { value: 'studio', label: 'Estudio' },

  { value: 'other', label: 'Otros' },
];

export const entityTypesObj = entityTypes.reduce(
  (acc, val) => ({ ...acc, [val.value]: val.label }),
  {},
);

export const ROLES_MAP = {
  ADMIN: 'admin',
  OWNER: 'owner',
  MEMBER: 'member',
};

export const generateCoolColor = () =>
  '#' + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0');

export const customFieldTypesObj = {
  Text: 'Texto',
  Number: 'Número',
  Select: 'Opciones',
  Checkbox: 'Confirmación',
  Date: 'Fecha',
} as const;

export const customFieldTypes = Object.entries(customFieldTypesObj).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

export const WhatsappContactLegalSurfLink =
  'https://api.whatsapp.com/send?phone=5491171250058&text=Hola!%20%E2%9C%8B%0AMe%20gustar%C3%ADa%20solicitar%20ayuda%20de%20un%20asesor%20de%20LegalSurf%20%F0%9F%8F%84%E2%80%8D%E2%99%82%EF%B8%8F';

export const calificationProps = {
  teamSizes: [
    { label: 'Solo yo', value: 'Solo yo' },
    { label: '2 a 3', value: '2-3' },
    { label: '3 a 5', value: '3-5' },
    { label: '5 a 10', value: '5-10' },
    { label: '10 a 20', value: '10-20' },
    { label: '20 a 50', value: '20-50' },
    { label: 'Más de 50', value: '+50' },
  ],
  filecases: [
    { label: 'Menos de 50', value: '-50' },
    { label: '50 a 250', value: '50-to-250' },
    { label: '250 a 500', value: '250-to-500' },
    { label: '500 a 1000', value: '500-to-1000' },
    { label: '1000 a 2000', value: '1000-to-2000' },
    { label: '2000 a 5000', value: '2000-to-5000' },
    { label: 'Más de 5000', value: '+5000' },
  ],
  types: [
    { label: 'Profesional Independiente', value: 'Profesional Independiente' },
    { label: 'Equipo Legal', value: 'Equipo Legal' },
    { label: 'Estudio Pequeño / Mediano', value: 'Estudio Pequeño / Mediano' },
    { label: 'Firma de Abogados', value: 'Firma de Abogados' },
    { label: 'Grandes Bufetes', value: 'Grandes Bufetes' },
    { label: 'Institución Pública', value: 'Institución Pública' },
    { label: 'Organización Privada', value: 'Organización Privada' },
    { label: 'Estudiante de Abogacía', value: 'Estudiante de Abogacía' },
    { label: 'Otro', value: 'Otro' },
  ],
};
