import React, { useState, useMemo } from 'react';
import {
  Button,
  IconButton,
  SwipeableDrawer,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AppBar,
  MenuItem,
  Menu,
  Toolbar,
  Avatar,
  Stack,
  darken,
  useScrollTrigger,
  Slide,
} from '@mui/material';
import {
  AccountCircleOutlined,
  ArrowBack,
  ExitToApp,
  InfoOutlined,
  Menu as MenuIcon,
  SettingsOutlined,
  Sync,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

import PermissionGuard from 'src/components/v2/PermissionGuard';
import { useBreakpoints } from 'src/utils/hooks/common';
import {
  LS_PERMISSIONS_MAP,
  WhatsappContactLegalSurfLink,
} from '@legalsurf/common';

import {
  LSDocument as LSDocumentIcon,
  LSFilecase,
  LSDirectory as LSDirectoryIcon,
  LSCalendar as LSCalendarIcon,
  LSTreasury as LSTreasuryIcon,
  LSPin as LSPinIcon,
  LSList as LSListIcon,
  LSStats,
  LSExpense,
  LSSale,
} from 'src/components/icons';
import { useUser } from 'src/utils/hooks/useUser';

import LSClock from 'src/components/icons/LSClock';
import { useAuth, useSession } from '@clerk/clerk-react';
import { SubscriptionNavbarButton } from './SubscriptionNavbarButton';
import { LogoButton } from './LogoButton';
import { StudioBlockedWrapper } from './StudioBlocked';
import { useStudioId } from 'src/utils/hooks/useStudioId';

const navOptions = [
  {
    icon: <LSListIcon color="primary" />,
    title: 'Inicio',
    href: '/home',
  },
  {
    icon: <LSFilecase color="primary" />,
    title: 'Expedientes',
    href: '/filecases',
    permissions: [LS_PERMISSIONS_MAP.FILECASES.ACCESS],
  },
  {
    icon: <LSDirectoryIcon color="primary" />,
    title: 'Directorio',
    href: '/directories',
    permissions: [LS_PERMISSIONS_MAP.DIRECTORY.ACCESS],
  },
  {
    icon: <LSCalendarIcon color="primary" />,
    title: 'Calendario',
    href: '/calendar',
    permissions: [LS_PERMISSIONS_MAP.CALENDAR.ACCESS],
  },
  {
    icon: <LSPinIcon color="success" sx={{ mr: 2 }} />,
    title: 'Tareas',
    href: '/task',
    permissions: [LS_PERMISSIONS_MAP.CALENDAR.ACCESS],
  },
  {
    icon: <LSClock color="primary" sx={{ mr: 2 }} />,
    title: 'Time Tracking',
    href: '/tracking-hours',
    permissions: [LS_PERMISSIONS_MAP.TIME_TRACKING.ACCESS],
  },
  {
    icon: <LSDocumentIcon color="primary" />,
    title: 'Documentos',
    href: '/documents',
    permissions: [LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS],
  },
  {
    icon: <LSTreasuryIcon color="primary" />,
    title: 'Honorarios',
    href: '/sales',
    permissions: [LS_PERMISSIONS_MAP.SALES.ACCESS],
  },
  {
    icon: <LSTreasuryIcon color="primary" />,
    title: 'Gastos',
    href: '/expenses',
    permissions: [LS_PERMISSIONS_MAP.EXPENSES.ACCESS],
  },
  {
    icon: (
      <>
        <LSStats color="primary" />
        <LSFilecase color="primary" sx={{ mr: 2 }} />
      </>
    ),
    title: 'Informe de expedientes',
    href: '/reports/filecases',
    permissions: [LS_PERMISSIONS_MAP.REPORTS.ACCESS_FILECASES],
  },
  {
    icon: (
      <>
        <LSStats color="primary" />
        <LSTreasuryIcon color="primary" sx={{ mr: 2 }} />
      </>
    ),
    title: 'Informe de tesoreria',
    href: '/reports/treasury',
    permissions: [LS_PERMISSIONS_MAP.REPORTS.ACCESS_TREASURY],
  },
];

const HideOnScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const {
  DOCUMENTS,
  FILECASES,
  DIRECTORY,
  CALENDAR,
  SALES,
  EXPENSES,
  REPORTS,
  TIME_TRACKING,
} = LS_PERMISSIONS_MAP;

const Navbar = () => {
  const studioId = useStudioId();
  const { user } = useUser();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { session } = useSession();
  const isImpersonated = !!session?.actor?.sub;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { signOut } = useAuth();

  const isStudioSelected = !!studioId && studioId !== 'undefined';

  const { isDesktop, isMobile } = useBreakpoints();

  // TODO: Reuse this menu for desktop app
  // TODO: Nesting navbar and routing cause weird close animation. Move drawer to a root layout.
  const mobileMenu = useMemo(
    () => (
      <Box sx={{ width: 400 }}>
        <Box sx={{ p: 4 }}>
          <IconButton onClick={() => setOpenDrawer(false)}>
            <ArrowBack />
          </IconButton>
        </Box>

        <Divider />

        <List component="nav">
          {navOptions.map((nav) => (
            <PermissionGuard
              key={nav.title}
              permissions={nav.permissions ?? undefined}
            >
              <ListItemButton
                LinkComponent={Link}
                to={`/dashboard/${studioId}${nav.href}`}
                onClick={() => setOpenDrawer(false)}
              >
                <ListItemIcon>{nav.icon}</ListItemIcon>

                <ListItemText primary={nav.title} />
              </ListItemButton>
            </PermissionGuard>
          ))}
        </List>
      </Box>
    ),
    [studioId],
  );

  const profileDropdownOptions = useMemo(
    () => [
      {
        text: 'Administrar perfil',
        to: `/profile`,
        icon: <AccountCircleOutlined color="primary" />,
      },
      {
        text: 'Cambiar estudio',
        to: '/dashboard/studios',
        icon: <Sync color="primary" />,
      },
      isStudioSelected && {
        text: 'Configuración de estudio',
        to: `/dashboard/${studioId}/settings`,
        somePermissions: true,
        icon: <SettingsOutlined color="primary" />,
        permissions: [LS_PERMISSIONS_MAP.CONFIG.ACCESS],
      },
      !isStudioSelected && {
        text: 'Obtener soporte',

        handleClick: () => {
          // TODO: Move whastapp legalsurf service to a env var
          window.open(WhatsappContactLegalSurfLink, '_blank').focus();
        },
        icon: <InfoOutlined color="primary" />,
      },
      // !isStudioSelected && {
      //   text: 'Guía de Ayuda',
      //   // eslint-disable-next-line no-alert
      //   handleClick: () => alert('manda la data Nico')
      // },
      {
        divider: true,
        key: 'divider-1',
      },
      {
        text: 'Cerrar sesión',
        icon: <ExitToApp color="primary" />,
        handleClick: () => {
          signOut();
          window.location.assign(import.meta.env.VITE_LS_LANDING_URL);
        },
      },
    ],
    [isStudioSelected, studioId, signOut],
  );

  return (
    <>
      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        {mobileMenu}
      </SwipeableDrawer>

      <Menu
        anchorEl={anchorEl}
        id="profile-menu"
        open={anchorEl?.id === 'profile-menu-button'}
        onClose={handleClose}
      >
        {profileDropdownOptions
          .filter((option) => !!option)
          .map((option) =>
            option.divider ? (
              <Divider key={option.key} />
            ) : (
              <PermissionGuard
                somePermissions
                key={option.text}
                permissions={option.permissions ?? undefined}
              >
                {option.to ? (
                  <MenuItem
                    component={Link}
                    sx={{ gap: 1 }}
                    to={option.to}
                    onClick={handleClose}
                  >
                    {option.icon} {option.text}
                  </MenuItem>
                ) : (
                  <MenuItem sx={{ gap: 1 }} onClick={option.handleClick}>
                    {option.icon} {option.text}
                  </MenuItem>
                )}
              </PermissionGuard>
            ),
          )}
      </Menu>

      <HideOnScroll>
        <AppBar
          position="sticky"
          sx={{
            backgroundColor: isImpersonated ? 'error.dark' : 'primary.600',
            boxShadow: 'none',
          }}
        >
          <Toolbar sx={{ minHeight: 46 }}>
            <Stack width="100%">
              <Stack alignItems="center" direction="row" py={0.5}>
                {isStudioSelected && !isDesktop && (
                  <IconButton
                    color="background"
                    onClick={() => setOpenDrawer(true)}
                  >
                    <MenuIcon titleAccess="Menu" />
                  </IconButton>
                )}

                <LogoButton />

                <Stack
                  direction="row"
                  gap={[0, 1]}
                  sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}
                >
                  {user?.role === 'owner' && <SubscriptionNavbarButton />}

                  {/* <NotificationsButton /> */}

                  <Button
                    id="profile-menu-button"
                    sx={{
                      color: 'white',
                      gap: 2,
                      textTransform: 'none',
                      fontSize: 14,
                    }}
                    onClick={handleClick}
                  >
                    {isMobile ? '' : user.name}

                    <Avatar
                      alt="Perfil de usuario"
                      src={user?.picture}
                      sx={{ width: 32, height: 32 }}
                    />
                  </Button>
                </Stack>
              </Stack>

              {isStudioSelected && isDesktop && (
                <Stack
                  direction="row"
                  gap={2}
                  sx={{
                    py: 1,
                    mx: -3,
                    backgroundColor: (theme) =>
                      darken(
                        isImpersonated
                          ? theme.palette.error.dark
                          : theme.palette.primary[600],
                        0.1,
                      ),
                  }}
                >
                  <Button
                    LinkComponent={Link}
                    color="inherit"
                    startIcon={<LSListIcon />}
                    sx={{ ml: 2 }}
                    to={`/dashboard/${studioId}/home`}
                    variant="text"
                  >
                    Dashboard
                  </Button>

                  <PermissionGuard permissions={[FILECASES.ACCESS]}>
                    <Button
                      LinkComponent={Link}
                      color="inherit"
                      startIcon={<LSFilecase />}
                      to={`/dashboard/${studioId}/filecases`}
                      variant="text"
                    >
                      Expedientes
                    </Button>
                  </PermissionGuard>

                  <PermissionGuard permissions={[DIRECTORY.ACCESS]}>
                    <Button
                      LinkComponent={Link}
                      color="inherit"
                      startIcon={<LSDirectoryIcon />}
                      sx={{ color: 'white' }}
                      to={`/dashboard/${studioId}/directories`}
                      variant="text"
                    >
                      Directorio
                    </Button>
                  </PermissionGuard>

                  <PermissionGuard permissions={[CALENDAR.ACCESS]}>
                    <Button
                      LinkComponent={Link}
                      startIcon={<LSCalendarIcon sx={{ color: '#fff' }} />}
                      sx={{ color: '#fff' }}
                      to={`/dashboard/${studioId}/calendar`}
                    >
                      Calendario
                    </Button>

                    <Button
                      LinkComponent={Link}
                      startIcon={<LSPinIcon />}
                      sx={{ color: '#fff' }}
                      to={`/dashboard/${studioId}/task`}
                    >
                      Tareas
                    </Button>
                  </PermissionGuard>

                  <PermissionGuard permissions={[TIME_TRACKING.ACCESS]}>
                    <Button
                      LinkComponent={Link}
                      startIcon={<LSClock />}
                      sx={{ color: '#fff' }}
                      to={`/dashboard/${studioId}/tracking-hours`}
                    >
                      Time Tracking
                    </Button>
                  </PermissionGuard>

                  <PermissionGuard
                    permissions={[SALES.ACCESS, EXPENSES.ACCESS]}
                  >
                    <Button
                      color="inherit"
                      id="treasury-menu-button"
                      startIcon={<LSTreasuryIcon />}
                      sx={{ color: 'white' }}
                      variant="text"
                      onClick={handleClick}
                    >
                      Tesoreria
                    </Button>

                    <Menu
                      anchorEl={anchorEl}
                      open={anchorEl?.id === 'treasury-menu-button'}
                      sx={{
                        '.MuiPaper-root': { backgroundColor: 'primary.600' },
                      }}
                      onClose={handleClose}
                    >
                      <PermissionGuard permissions={[SALES.ACCESS]}>
                        <MenuItem
                          component={Link}
                          sx={{ color: 'white' }}
                          to={`/dashboard/${studioId}/sales`}
                          onClick={handleClose}
                        >
                          <LSSale
                            sx={{
                              color: 'white',
                              borderRadius: 0.2,
                              mr: 2,
                            }}
                          />
                          Honorarios
                        </MenuItem>
                      </PermissionGuard>

                      <PermissionGuard permissions={[EXPENSES.ACCESS]}>
                        <MenuItem
                          component={Link}
                          sx={{ color: 'white' }}
                          to={`/dashboard/${studioId}/expenses`}
                          variant="text"
                          onClick={handleClose}
                        >
                          <LSExpense
                            sx={{
                              borderRadius: 0.2,
                              color: 'white',
                              mr: 2,

                              '& rect, & path': {
                                fill: 'white',
                              },
                            }}
                          />
                          Gastos
                        </MenuItem>
                      </PermissionGuard>
                    </Menu>
                  </PermissionGuard>

                  <PermissionGuard permissions={DOCUMENTS.ACCESS}>
                    <Button
                      LinkComponent={Link}
                      startIcon={<LSDocumentIcon />}
                      sx={{ color: 'white' }}
                      to={`/dashboard/${studioId}/documents`}
                      variant="text"
                    >
                      Documentos
                    </Button>
                  </PermissionGuard>

                  <PermissionGuard
                    permissions={[
                      REPORTS.ACCESS_FILECASES,
                      REPORTS.ACCESS_TREASURY,
                    ]}
                  >
                    <Button
                      color="inherit"
                      id="metrics-menu-button"
                      startIcon={<LSStats />}
                      sx={{ color: 'white' }}
                      variant="text"
                      onClick={handleClick}
                    >
                      Informes
                    </Button>

                    <Menu
                      anchorEl={anchorEl}
                      open={anchorEl?.id === 'metrics-menu-button'}
                      sx={{
                        '.MuiPaper-root': { backgroundColor: 'primary.600' },
                      }}
                      onClose={handleClose}
                    >
                      <MenuItem
                        component={Link}
                        sx={{ color: '#fff' }}
                        to={`/dashboard/${studioId}/reports/filecases`}
                        onClick={handleClose}
                      >
                        <LSFilecase sx={{ mr: 2, color: '#fff' }} />
                        Expedientes
                      </MenuItem>

                      <MenuItem
                        component={Link}
                        sx={{ color: '#fff' }}
                        to={`/dashboard/${studioId}/reports/treasury`}
                        onClick={handleClose}
                      >
                        <LSTreasuryIcon sx={{ mr: 2, color: '#fff' }} />
                        Tesoreria
                      </MenuItem>
                    </Menu>
                  </PermissionGuard>
                </Stack>
              )}
            </Stack>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

export const NavbarBox = ({ children, ...props }) => (
  <>
    <Navbar {...props} />

    <StudioBlockedWrapper>{children}</StudioBlockedWrapper>
  </>
);

export default Navbar;
