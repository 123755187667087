import React from 'react';
import {
  Stack,
  Card,
  CardContent,
  Divider,
  Typography,
  Button,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';

import { useCalendarEvents } from '@legalsurf/hooks';

import { LSAdd as LSAddIcon, LSCalendar } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';
import EmptyState from 'src/components/v3/EmptyState';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import dayjs from 'dayjs';
import EventsTable from 'src/tables/EventsTable';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowRight } from '@mui/icons-material';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

const EventsSection = () => {
  const studioId = useStudioId();
  const navigate = useNavigate();
  const dispatchEventFormDialog = useDialog('eventForm');

  const { data: events, loading } = useCalendarEvents({
    studio: studioId,
    start: dayjs().startOf('date'),
    end: dayjs().endOf('date'),
  });

  return (
    <Card>
      <CardContent sx={{ pb: 1 }}>
        <Stack direction="row" gap={1} alignItems="center">
          <LSCalendar color="primary" />

          <Typography variant="h6" fontWeight="600">
            Agenda del día
          </Typography>

          <Stack direction="row" gap={1} alignItems="center" ml="auto">
            <PermissionGuard permissions={[LS_PERMISSIONS_MAP.CALENDAR.CREATE]}>
              <Button
                color="primary"
                startIcon={<LSAddIcon color="white" />}
                variant="text"
                onClick={() => dispatchEventFormDialog('EventsFormDialog')}
              >
                Crear alerta / tarea
              </Button>
            </PermissionGuard>

            <PermissionGuard permissions={[LS_PERMISSIONS_MAP.CALENDAR.ACCESS]}>
              <IconButton
                size="small"
                sx={{ bgcolor: 'primary.200' }}
                onClick={() => navigate(`/dashboard/${studioId}/calendar`)}
              >
                <KeyboardArrowRight color="primary" />
              </IconButton>
            </PermissionGuard>
          </Stack>
        </Stack>
      </CardContent>

      <Divider />

      <CardContent>
        {loading && (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress size={20} />
          </Stack>
        )}

        {!loading && !events.length && (
          <EmptyState color="primary">
            <Typography color="primary">
              No tienes ningún evento de calendario el día de hoy.
            </Typography>

            <Box>
              <Button
                color="primary"
                startIcon={<LSAddIcon color="white" />}
                variant="contained"
                onClick={() => dispatchEventFormDialog('CalendarPageEventForm')}
              >
                Crear alerta / tarea
              </Button>
            </Box>
          </EmptyState>
        )}

        {events.length > 0 && (
          <Stack
            direction="column"
            gap={2}
            sx={{
              '& .MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          >
            <EventsTable loading={loading} rows={events} />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default EventsSection;
