import React from 'react';
import { Popover } from '@mui/material';

export const PopoverBase = ({
  children,
  transformOrigin = {},
  anchorOrigin = {},
  ...props
}) => (
    <Popover
      {...props}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
        ...anchorOrigin,
      }}
      slotProps={{
        paper: {
          sx: {
            boxShadow: 'none',
            border: '1px solid #d2d2d2',
          },
        },
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
        ...transformOrigin,
      }}
    >
      {children}
    </Popover>
);
