import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import {
  Box,
  Typography,
  ButtonBase,
  Fab,
  Grow,
  Stack,
  Tooltip,
  Avatar,
  Fade,
} from '@mui/material';
import { useMyStudios, useResolveStudioInvitation } from '@legalsurf/hooks';
import { useOrganizationList } from '@clerk/clerk-react';

import Page from 'src/components/v2/Page';
import StudioCard from 'src/components/v3/StudioCard';
import { LSAdd } from 'src/components/icons';
import { useUser } from 'src/utils/hooks/useUser';
import StudioInviteCard from 'src/components/v3/StudioInviteCard';

import { useStudioInvitations } from 'src/utils/hooks/useStudioInvitations';
import { WhatsappContactLegalSurfLink } from '@legalsurf/common';
import wsIcon from '../Settings/600px-Whatsapp_logo.png';
import StudiosSkeleton from './StudiosSkeleton';
import ExternalResources from './ExternalResources';
import { TransitionGroup } from 'react-transition-group';

const Studios = () => {
  const navigate = useNavigate();
  const { setActive } = useOrganizationList();
  const { invitations, loading: loadingInvitations } = useStudioInvitations();

  const { user, loading: loadingUser } = useUser();
  const [isResolvingAcceptInvitation, setIsResolvingAcceptInvitation] =
    useState(false);
  const { data: studios, loading: loadingStudios } = useMyStudios();
  const [resolveStudioInvitation, { loading: isResolvingRevokeInvitation }] =
    useResolveStudioInvitation();

  const handleStudioThumbClick = (studio) => {
    setActive({
      organization: studio.id,
    });

    navigate(`/dashboard/${studio.id}/home`);
  };

  const revokeInvitationConsent = (invitation) =>
    resolveStudioInvitation({
      variables: {
        studioId: invitation.publicOrganizationData.id,
        invitationId: invitation.id,
        consent: false,
      },
    });

  if (loadingStudios || loadingUser || loadingInvitations) {
    return (
      <Page
        sx={{
          backgroundColor: 'primary.600',
          minHeight: '100vh',
        }}
        title="Selecciona tu estudio | LegalSurf"
      >
        <StudiosSkeleton />
      </Page>
    );
  }

  return (
    <Page
      sx={{
        backgroundColor: 'primary.600',
        minHeight: '100vh',
        pt: 5,

        '& .MuiTooltip-tooltip, & .MuiTooltip-arrow::before': {
          backgroundColor: '#C5FFFF !important',
        },
      }}
      title="Selecciona tu estudio | LegalSurf"
    >
      <Stack
        direction={['column', 'column', 'row']}
        gap={3}
        justifyContent="space-between"
        sx={{
          maxWidth: 1500,
        }}
      >
        <Stack direction="column" gap={2} maxWidth={450}>
          <Grow in>
            <Box>
              <Typography
                color="white"
                fontWeight={500}
                sx={{ wordWrap: 'break-word' }}
                variant="h2"
              >
                Hola <b>{user.name || user.email} 👋</b>
              </Typography>

              <Typography
                color="textSecondary"
                fontWeight="500"
                sx={{ mt: 1, color: 'common.white' }}
                variant="h5"
              >
                Selecciona el estudio de <b>LegalSurf</b> con el que quieres
                trabajar
              </Typography>
            </Box>
          </Grow>

          {invitations?.length > 0 && (
            <Fade in>
              <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
                {invitations.map((invitation) => (
                  <StudioInviteCard
                    disabled={
                      isResolvingRevokeInvitation || isResolvingAcceptInvitation
                    }
                    key={invitation.id}
                    name={invitation?.publicOrganizationData?.name}
                    picture={invitation?.publicOrganizationData?.imageUrl}
                    onAccept={async () => {
                      setIsResolvingAcceptInvitation(true);
                      try {
                        await invitation.accept();
                        setTimeout(() => {
                          window.location.reload();
                          setIsResolvingAcceptInvitation(false);
                        }, 3000);
                      } catch (err) {
                        Sentry.captureException(err);
                        window.location.reload();
                        setIsResolvingAcceptInvitation(false);
                      }
                    }}
                    onCancel={() => revokeInvitationConsent(invitation, false)}
                  />
                ))}
              </Box>
            </Fade>
          )}

          {!loadingStudios && (
            <>
              <TransitionGroup component={Stack} gap={1}>
                {studios.map((studio) => (
                  <Fade in key={studio.id}>
                    <Box>
                      <StudioCard
                        studio={studio}
                        onClick={() => handleStudioThumbClick(studio)}
                      />
                    </Box>
                  </Fade>
                ))}
              </TransitionGroup>

              <Fade in>
                <Tooltip
                  arrow
                  PopperProps={{
                    disablePortal: true,
                  }}
                  enterDelay={10000}
                  open={!studios.length}
                  placement="bottom-end"
                  title={
                    <Stack direction="column" p={1} spacing={1}>
                      <Typography color="#434343" variant="h6">
                        ¡Comienza aquí! 🏄🏼‍♂️
                      </Typography>

                      <Typography color="#434343">
                        Dá tu primer paso y crea tu estudio
                      </Typography>
                    </Stack>
                  }
                >
                  <ButtonBase
                    component={Link}
                    sx={{
                      p: 4,
                      backgroundColor: '#C5D3F6',
                      borderRadius: 1,
                      overflow: 'hidden',
                      justifyContent: 'flex-start',
                      transition: 'scale 0.2s ease-in-out',

                      '&:hover': {
                        scale: '1.01',
                      },
                    }}
                    to="/onboarding/studio"
                  >
                    <LSAdd color="primary" sx={{ mr: 2 }} />
                    Crear nuevo estudio
                  </ButtonBase>
                </Tooltip>
              </Fade>
            </>
          )}
        </Stack>

        <ExternalResources />
      </Stack>

      <Fab
        color="success"
        sx={{
          position: 'fixed',
          bottom: 32,
          right: 32,
        }}
        variant="extended"
        onClick={() => {
          window.open(
            WhatsappContactLegalSurfLink,
            '_blank',
            // Idk if passing noopener works for window.open
            'noopener',
          );
        }}
      >
        <Avatar
          src={wsIcon}
          sx={{
            mr: 1,
            width: 24,
            height: 24,
          }}
        />
        Habla con nosotros
      </Fab>
    </Page>
  );
};

export default Studios;
