import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import {
  LSAdd as LSAddIcon,
  LSDirectory as LSDirectoryIcon,
} from 'src/components/icons';
import { Formik } from 'formik';

import { useMutation } from '@apollo/client';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import * as Yup from 'yup';

import { EntitiesAutocomplete } from 'src/autocompletes';
import Form from 'src/components/ui-components/Form';
import { useDialog } from 'src/dialogs/Dialogs';
import { useStudioId } from 'src/utils/hooks/useStudioId';

import { ADD_ENTITY_TO_FILECASE } from '../../../../graphql/mutations/filecases';

import Entity from './Entity';
import PermissionGuard from '../../PermissionGuard';

export const SingleFilecaseEntities = ({ filecase }) => {
  const studioId = useStudioId();
  const dispatchEntityForm = useDialog('entityForm');

  const [addEntityToFilecase] = useMutation(ADD_ENTITY_TO_FILECASE);

  return (
    <Box>
      {Array.isArray(filecase.entities) &&
        filecase.entities.map((entity) => (
          <Entity entity={entity} filecase={filecase} key={entity.id} />
        ))}

      <PermissionGuard
        permissions={[
          LS_PERMISSIONS_MAP.DIRECTORY.CREATE,
          LS_PERMISSIONS_MAP.FILECASES.UPDATE,
        ]}
      >
        <Card
          className="FormCard"
          sx={{
            border: '1px dashed',
            borderColor: 'primary.main',
            backgroundColor: 'primary.100',
            boxShadow: 0,
          }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <LSDirectoryIcon color="primary" />

              <Box>
                <Typography
                  sx={{ fontWeight: '800', fontSize: 14 }}
                  variant="h6"
                >
                  Vincular personas del Directorio
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: 14,
                    color: 'rgb(126, 130, 153)',
                  }}
                  variant="h6"
                >
                  Vincula todo tipo de personas de tu directorio
                </Typography>
              </Box>
            </Box>

            <Formik
              initialValues={{
                entity: null,
              }}
              validationSchema={Yup.object().shape({
                entity: Yup.object()
                  .required('Vincula a alguien para para poder agregarlo')
                  .nullable(),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                await addEntityToFilecase({
                  variables: {
                    studioId,
                    entity: values.entity.value,
                    filecase: filecase.id,
                  },
                });

                resetForm({
                  values: {
                    entity: null,
                  },
                });
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, errors, values }) => (
                <Form style={{ marginLeft: 32 }}>
                  <PermissionGuard
                    permissions={[LS_PERMISSIONS_MAP.FILECASES.UPDATE]}
                  >
                    <EntitiesAutocomplete
                      getOptionDisabled={(option) =>
                        filecase.entities?.some(
                          (entity) => entity.id === option.value,
                        )
                      }
                      label="Buscar en directorio"
                      name="entity"
                    />
                  </PermissionGuard>

                  <Grid item>
                    <PermissionGuard
                      permissions={[LS_PERMISSIONS_MAP.FILECASES.UPDATE]}
                    >
                      <Button
                        color="primary"
                        disabled={
                          errors.entity || !values.entity || isSubmitting
                        }
                        loading={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Vincular
                      </Button>
                    </PermissionGuard>

                    <PermissionGuard
                      permissions={[LS_PERMISSIONS_MAP.DIRECTORY.CREATE]}
                    >
                      <Button
                        color="primary"
                        startIcon={<LSAddIcon />}
                        sx={{ ml: 2 }}
                        type="button"
                        onClick={() =>
                          dispatchEntityForm(
                            'SingleFilecaseEntitiesEntityForm',
                            {
                              filecaseId: filecase.id,
                            },
                          )
                        }
                      >
                        Crear directorio
                      </Button>
                    </PermissionGuard>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </PermissionGuard>
    </Box>
  );
};
