import React, { useCallback } from 'react';
import { Box, Button, ButtonGroup, InputAdornment, Stack } from '@mui/material';
import { ArrayParam, StringParam, BooleanParam } from 'use-query-params';

import SectionHeader, {
  SectionHeaderHeading,
  SectionHeaderActiveFilters,
} from 'src/components/v3/SectionHeader';
import { useDialog } from 'src/dialogs/Dialogs';
import SectionHeaderAdvanceFilters from 'src/components/v3/SectionHeader/SectionHeaderAdvanceFilters';
import {
  DateField,
  SwitchField,
  TextField,
} from 'src/components/ui-components/Form';
import { LSAdd as LSAddIcon, LSLen } from 'src/components/icons';
import {
  AssigneesAutocomplete,
  CalendarsAutocomplete,
  FilecasesAutocomplete,
} from 'src/autocompletes';
import { createHeaderFiltersStore } from 'src/components/v3/SectionHeader/utils';
import { useFilters } from 'src/components/v3/SectionHeader/useFilters';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

export const taskBoardSearchFiltersId = 'TaskBoardSearchFilters-v2';

const paramConfigMap = {
  filecases: ArrayParam,
  assigned: ArrayParam,
  calendars: ArrayParam,
  text: StringParam,
  start: StringParam,
  end: StringParam,
  onlyMe: BooleanParam,
  viewType: StringParam,
  state: StringParam,
};

export const taskTableHeaderDefaultInitialValues = {
  filecases: [],
  calendars: [],
  text: '',
  start: null,
  end: null,
  onlyMe: false,
  viewType: 'board',
  state: ['pending', 'wip', 'done', 'reviewing'],
  assigned: [],
};

const useEntitiesFiltersStore = createHeaderFiltersStore(
  taskBoardSearchFiltersId,
  taskTableHeaderDefaultInitialValues,
);

export const useTaskFilters = () => {
  return useFilters(
    useEntitiesFiltersStore,
    paramConfigMap,
    taskTableHeaderDefaultInitialValues,
  );
};

const Header = ({ title, subtitle, loading }) => {
  const dispatchTaskFormDialog = useDialog('eventForm');

  const { filters, setFilters, resetFilters, initialValues } = useTaskFilters();

  const handleFiltersChange = useCallback(
    (values, prev) =>
      Promise.resolve(setFilters(values, values.text !== prev.text)),
    [setFilters],
  );

  const handleReset = useCallback(
    (formikBag) => {
      resetFilters();
      formikBag.setValues(taskTableHeaderDefaultInitialValues);
    },
    [setFilters, resetFilters],
  );
  return (
    <SectionHeader
      filters={initialValues}
      loading={loading}
      onChange={handleFiltersChange}
      onSubmit={handleFiltersChange}
    >
      {({ values, setValues }) => (
        <>
          <Stack alignItems="center" direction="row" gap={3}>
            <Stack direction="column">
              <SectionHeaderHeading variant="h3">{title}</SectionHeaderHeading>

              {subtitle && (
                <SectionHeaderHeading variant="subtitle1">
                  {subtitle}
                </SectionHeaderHeading>
              )}
            </Stack>

            <TextField
              debounce
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LSLen color="primary" />
                  </InputAdornment>
                ),
              }}
              name="text"
              placeholder="Buscar..."
              size="small"
            />

            <DateField
              label="Desde"
              name="start"
              size="small"
              sx={{ maxWidth: 160 }}
            />

            <DateField
              label="Hasta"
              name="end"
              size="small"
              sx={{ maxWidth: 160 }}
            />

            <SectionHeaderAdvanceFilters onReset={handleReset}>
              <CalendarsAutocomplete
                multiple
                label="Calendarios"
                name="calendars"
                size="small"
              />

              <FilecasesAutocomplete
                multiple
                label="Expedientes"
                name="filecases"
                placeholder="Ej: 1234/2021"
                size="small"
              />

              <AssigneesAutocomplete
                multiple
                label="Asignados"
                name="assigned"
                placeholder="Ej: Nicolas Salazar"
                size="small"
              />
            </SectionHeaderAdvanceFilters>

            <Box
              sx={{
                ml: 'auto',
                gap: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SwitchField label="Solo yo" name="onlyMe" />

              <ButtonGroup variant="outlined">
                <Button
                  variant={
                    filters.viewType === 'list' ? 'contained' : 'outlined'
                  }
                  onClick={() =>
                    setValues({
                      ...values,
                      viewType: 'list',
                      state: 'pending',
                    })
                  }
                >
                  Lista
                </Button>

                <Button
                  variant={
                    filters.viewType === 'board' ? 'contained' : 'outlined'
                  }
                  onClick={() =>
                    setValues({
                      ...values,
                      viewType: 'board',
                      state: null,
                    })
                  }
                >
                  Tablero
                </Button>
              </ButtonGroup>

              <PermissionGuard
                permissions={[LS_PERMISSIONS_MAP.CALENDAR.CREATE]}
              >
                <Button
                  color="primary"
                  startIcon={<LSAddIcon color="white" />}
                  variant="contained"
                  onClick={() =>
                    dispatchTaskFormDialog('TaskBoard', {
                      type: 'task',
                    })
                  }
                >
                  Crear tarea
                </Button>
              </PermissionGuard>
            </Box>
          </Stack>

          <SectionHeaderActiveFilters
            filterLabels={{
              filecase: 'Expediente',
              filecases: 'Expedientes',
              assigned: 'Asignados',
              onlyMe: 'Solo yo',
              start: 'Desde',
              end: 'Hasta',
              text: 'Texto',
            }}
            filters={filters}
          />
        </>
      )}
    </SectionHeader>
  );
};

export default Header;
