import React from 'react';

import { Box, Alert, AlertTitle } from '@mui/material';

const SingleFilecaseErrorBoundary = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    <Alert color="error">
      {/* maldita deuda */}
      <AlertTitle>
        Verifica que tienes permiso de acceso o contacta a tu administrador.
      </AlertTitle>

      Algo ha occurrido mientras cargabamos tu expediente, estamos
      solucionandolo ahora mismo. Intente mas tarde.
    </Alert>
  </Box>
);

export default SingleFilecaseErrorBoundary;
