import React from 'react';
import {
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  Avatar,
  useTheme,
  Tooltip,
  Divider,
  Stack,
  ListItemIcon,
  Card,
  CardContent,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { Circle } from '@mui/icons-material';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import { palette } from 'src/config/mui-theme/foundations';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import {
  LSEdit as LSEditIcon,
  LSList as LSListIcon,
  LSX as LSXIcon,
  LSExternal as LSExternalIcon,
  LSTrash,
  LSActivity,
  LSDirectory,
} from 'src/components/icons';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { useDialog } from 'src/dialogs/Dialogs';
import { Linkify } from 'src/components/Linkify';
import { Badge } from 'src/components/Badge';
import dayjs from 'dayjs';
import { AssigneesPopover } from 'src/components/v3/AssigneesPopover';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { WhatsAppButton } from 'src/popovers/NotificationTemplatesPopover/WhatsappButton';
import { EntityStateButton } from 'src/components/v3/EntityStatusPopover/EntityStateButton';
import { getEntityType, stringAvatar } from 'src/utils/formatters';
import { UpdateEntityAssigneesMutation } from 'src/tables/DirectoriesTable/AssignedCell';
import { EntityMetadata } from './SingleEntityTreasury/EntityMetadata';
import {
  formatCustomFieldValue,
  isCustomFieldInitialValue,
} from 'src/components/ui-components/Form/CustomField';
import { getDetailedChanges } from 'src/components/v3/Table/LogCell';
import { DELETE_ENTITY } from 'src/graphql/mutations/entities';

const LatestLogDetailCard = ({ log }) => {
  const changes = getDetailedChanges(log);

  if (changes?.length === 0) {
    return null;
  }

  return (
    <Card
      sx={{
        borderColor: 'background.main',
        boxShadow: 0,
      }}
      elevation={0}
      variant="outlined"
    >
      <CardContent sx={{ pb: '0' }}>
        <Stack pl={1} gap={1.5}>
          <Stack direction="row" alignItems="center" gap={1}>
            <LSActivity color="primary" fontSize="small" />

            <Typography color="primary" fontWeight={500} fontSize={16}>
              Actividad reciente
            </Typography>
          </Stack>

          <Divider sx={{ mr: -2, ml: -3 }} />

          <Stack gap={1}>
            <Stack direction="row" gap={1} alignItems="center">
              <Stack direction="row" gap={1} alignItems="center">
                <Avatar
                  alt={log.user.name}
                  src={log.user.picture}
                  sx={{ height: 28, width: 28 }}
                />

                <Typography fontWeight={600} fontSize={14}>
                  {log.user.name}
                </Typography>
              </Stack>

              <Typography>-</Typography>

              <Typography fontSize={13}>
                {dayjs(log.createdAt).fromNow()}
              </Typography>
            </Stack>

            <Stack
              whiteSpace="break-spaces"
              lineHeight="1.25rem"
              fontWeight={500}
              fontSize={13}
            >
              {changes.join('\n').trim()}
            </Stack>
          </Stack>

          {/* 
          <CommentTooltip
            placement="bottom"
            title={tooltipContent}
            slotProps={{
              tooltip: {
                sx: {
                  whiteSpace: 'break-spaces',
                  fontSize: 14,
                },
              },
            }}
          >
            <Stack direction="row" alignItems="center" gap={0.5}> */}
          {/* <LSClock color="primary" sx={{ fontSize: '1.25rem' }} /> */}
          {/* <Box whiteSpace="break-spaces" lineHeight="1rem">
                {summary}
              </Box>
            </Stack>
          </CommentTooltip> */}
        </Stack>
      </CardContent>
    </Card>
  );
};
export const SingleEntityHeader = ({ entity, onClose, singlePage }) => {
  const theme = useTheme();
  const studioId = useStudioId();

  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const dispatchEntityFormDialog = useDialog('entityForm');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const moreOptionsMenuOpen = Boolean(anchorEl);

  const [deleteEntity] = useMutation(DELETE_ENTITY, {
    update: (cache, { data }) => {
      cache.evict({
        id: cache.identify(data?.deleteEntity),
      });
    },
  });

  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DIRECTORY.UPDATE,
  ]);

  const handleMoreOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteEntity = () => {
    onClose();

    deleteEntity({
      variables: {
        id: entity.id,
        studio: studioId,
      },
    });
  };

  const [updateEntityAssignees] = useMutation(UpdateEntityAssigneesMutation);

  const handleAssignedSubmit = async (newAssignees) => {
    await updateEntityAssignees({
      variables: {
        id: entity.id,
        assigned: newAssignees,
      },
    });
  };

  const customFields = entity?.customFields?.filter(
    (customField) => !isCustomFieldInitialValue(customField),
  );

  return (
    <Stack gap={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Tooltip title={entity.businessname ? 'Organización' : 'Persona'}>
          <Stack alignItems="center" direction="row" gap={1}>
            {entity.businessname ? (
              <LSDirectory color="primary" type="organization" />
            ) : (
              <LSDirectory color="primary" />
            )}

            <Typography variant="h4" fontWeight={600}>
              {entity.businessname ? 'Organización' : 'Persona'}
            </Typography>
          </Stack>
        </Tooltip>

        <Stack direction="row" sx={{ textAlign: 'right' }}>
          <WhatsAppButton
            phone={entity?.phone}
            entity={entity}
            data={{ entity }}
          />

          <PermissionGuard permissions={[LS_PERMISSIONS_MAP.DIRECTORY.UPDATE]}>
            <Tooltip color="primary" title="Editar directorio">
              <IconButton
                sx={{
                  p: 1,
                  '&:hover': {
                    backgroundColor: palette.error[200],

                    '& svg': {
                      color: 'error.main',
                    },
                  },
                }}
                onClick={() =>
                  dispatchEntityFormDialog(`SingleEntityHeader-${entity.id}`, {
                    entity,
                  })
                }
              >
                <LSEditIcon color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
          </PermissionGuard>

          {!singlePage && (
            <Tooltip color="primary" title="Pantalla completa">
              <IconButton
                sx={{
                  p: 1,
                }}
                onClick={() =>
                  window.open(
                    `/dashboard/${studioId}/directories/${entity.id}`,
                    '_blank',
                  )
                }
              >
                <LSExternalIcon color="primary" sx={{ margin: 'auto' }} />
              </IconButton>
            </Tooltip>
          )}

          <PermissionGuard permissions={[LS_PERMISSIONS_MAP.DIRECTORY.DELETE]}>
            <IconButton
              aria-controls="entity-more-options-menu"
              aria-expanded={moreOptionsMenuOpen ? 'true' : undefined}
              aria-haspopup="true"
              color="primary"
              id="entity-more-options-button"
              onClick={handleMoreOptionsClick}
            >
              <LSListIcon />
            </IconButton>
          </PermissionGuard>

          {!singlePage && (
            <IconButton onClick={onClose}>
              <LSXIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>

      <Divider />

      <Stack gap={2}>
        <Stack>
          <Stack
            direction={['column', 'row', 'row']}
            justifyContent={['initial', 'initial', 'space-between']}
            gap={[2, 4]}
          >
            <Stack gap={2}>
              <Stack direction="row" gap={2} alignItems="center">
                <Avatar
                  variant="squared"
                  {...stringAvatar(entity.displayName, {
                    width: 60,
                    height: 60,
                  })}
                />

                <Typography variant="h3">{entity.displayName}</Typography>
              </Stack>

              {entity.description && <Linkify text={entity.description} />}

              {!!customFields?.length && (
                <Stack>
                  {customFields?.map((customField) => (
                    <Stack
                      direction="row"
                      key={customField.name}
                      gap={1}
                      alignItems="center"
                    >
                      <Circle sx={{ fontSize: 10 }} />

                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        sx={{ opacity: 0.7 }}
                      >
                        {customField.name}:
                      </Typography>

                      <Typography fontWeight="600" fontSize={12}>
                        {formatCustomFieldValue(customField)}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              )}

              {!!(Array.isArray(entity.tags) && entity.tags.length) && (
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {entity.tags.map((tag) => (
                    <Chip
                      key={tag.name}
                      label={tag.name}
                      size="small"
                      sx={{
                        color: theme.palette.primary.dark,
                        backgroundColor: theme.palette.primary[100],
                        fontWeight: '500',
                      }}
                    />
                  ))}
                </Box>
              )}

              <Stack gap={0.5}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography fontWeight="600" fontSize={12}>
                    Fecha de creación:
                  </Typography>

                  <Typography fontWeight="400" fontSize={12}>
                    {dayjs(entity.createdAt).format('LLL[hs]')}
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" gap={1}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography fontWeight={600} fontSize={12}>
                      Creado por:
                    </Typography>

                    <Avatar
                      alt={entity.createdBy.name}
                      src={entity.createdBy.picture}
                      sx={{ width: 25, height: 25, fontSize: 12 }}
                    />
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={entity.assigned?.length ? 0 : 0.5}
                    justifyContent="flex-start"
                  >
                    <Typography
                      whiteSpace="nowrap"
                      fontWeight={600}
                      fontSize={12}
                    >
                      Asignado a:
                    </Typography>

                    <AssigneesPopover
                      selectedAssignees={entity.assigned}
                      touchable={hasPermissions}
                      onSubmit={handleAssignedSubmit}
                      sx={{
                        justifyContent: 'flex-start',
                        '.MuiAvatar-root': {
                          width: 25,
                          height: 25,
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack gap={[2, 3]}>
              <Stack direction="row" gap={1}>
                <EntityStateButton entity={entity} />

                <Badge color="primary" label={getEntityType(entity.type)} />
              </Stack>

              <EntityMetadata entity={entity} />
            </Stack>
          </Stack>
          <Menu
            MenuListProps={{
              'aria-labelledby': 'entity-more-options-button',
            }}
            anchorEl={anchorEl}
            id="entity-more-options-menu"
            open={moreOptionsMenuOpen}
            onClose={handleMoreOptionsClose}
          >
            <MenuItem
              onClick={() => {
                dispatchWarningPromptDialog('DeleteEntitySingleEntityHeader', {
                  onAccept: handleDeleteEntity,
                  title: 'Eliminar Directorio',
                  content: `Estas seguro que deseas eliminar la ${entity.businessname ? 'Organización' : 'Persona'} ${entity.displayName}`,
                });

                handleMoreOptionsClose();
              }}
            >
              <ListItemIcon>
                <LSTrash fontSize="small" />
              </ListItemIcon>
              Eliminar
            </MenuItem>
          </Menu>
        </Stack>

        {entity?.latestLog && <LatestLogDetailCard log={entity.latestLog} />}
      </Stack>
    </Stack>
  );
};
