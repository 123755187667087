import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Typography, Avatar, Stack } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import { usePermissions } from 'src/utils/hooks/usePermissions';
import { GET_FILECASE_SINGLE_DATA } from 'src/graphql/queries/filecases';

import { getHoursTrackingTableDataQuery } from 'src/pages/Dashboard/HoursTracking';
import dayjs from 'dayjs';
import { useDialog } from 'src/dialogs/Dialogs';
import { deleteHourMutation } from 'src/components/v2/FilecaseDetail/HoursTrackingSection';
import { humanizeHoursTrackingTime } from 'src/components/v2/FilecaseDetail/HoursTrackingSection/utils';
import Table, { defaultPageSize } from 'src/components/v3/Table';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { LSTrash } from 'src/components/icons';

const HoursTrackingTable = ({
  data: { getHoursTracking: hoursTracking } = {
    getHoursTracking: { data: [], count: 0 },
  },
  variables,
  loading,
  refetch,
}) => {
  const dispatchFilecaseDialog = useDialog('filecase');
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const [pagination, setPagination] = useState({
    page: 0,
    limit: defaultPageSize,
  });

  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.FILECASES.ACCESS,
  ]);

  const [deleteHourTracking] = useMutation(
    deleteHourMutation,
    {
      refetchQueries: [
        getHoursTrackingTableDataQuery,
        GET_FILECASE_SINGLE_DATA,
      ],
    },
  );

  const columns = [
    {
      hideable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: 'Usuario',
      field: 'assigned',
      minWidth: 200,
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Avatar src={row.assignedTo.picture} sx={{ width: 32, height: 32 }} />

          <Typography>{row.assignedTo.name}</Typography>
        </Box>
      )
    },
    {
      hideable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: 'Fecha',
      field: 'amount',
      renderCell: ({ row }) => dayjs(row.createdAt).format('L'),
    },
    {
      hideable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: 'Horas totales',
      field: 'createdAt',
      renderCell: ({ row }) => humanizeHoursTrackingTime(row.amount),
    },
    {
      hideable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerName: 'Expediente',
      field: 'filecase.title',
      renderCell: ({ row }) => (
        <Box
          sx={{
            maxWidth: '40rem',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            '& > * + *': { marginLeft: 1 },
          }}
        >
          <Typography
            role="button"
            sx={{
              cursor: hasPermissions ? 'pointer' : 'initial',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',
              gap: 1,

              ...(hasPermissions && {
                '&:hover': {
                  color: 'primary.main',
                },
              }),
            }}
            onClick={() => {
              if (hasPermissions) {
                dispatchFilecaseDialog(row.filecase.id, { id: row.filecase.id });
              }
            }}
          >
            {row.filecase.title}

            {hasPermissions && <OpenInNewIcon />}
          </Typography>
        </Box>
      ),
    },
    ...(hasPermissions
      ? [
        {
          field: 'ations',
          type: 'actions',
          headerName: 'Acciones',
          width: 120,
          cellClassName: 'actions',

          // eslint-disable-next-line react/no-unstable-nested-components
          getActions: ({ row }) => [
            <GridActionsCellItem
              icon={<LSTrash color="error" />}
              // label="Eliminar usuario"
              sx={{
                color: 'primary.main',
              }}
              onClick={() =>
                dispatchWarningPromptDialog(
                  `StudioMembersTableColumnsDeleteUser`,
                  {
                    onAccept: () => deleteHourTracking({
                      variables: {
                        id: row.id,
                      },
                    }),
                  },
                )
              }
            />,
          ]
        },
      ]
      : []),
  ];

  const handlePaginationModelChange = ({ pageSize, page }) =>
    refetch({
      ...variables,
      limit: pageSize,
      offset: pageSize * page,
    }).then(() => {
      setPagination({ page, limit: pageSize });
    });

  return (
    <Stack>
      <Box sx={{ p: 2, backgroundColor: 'white' }}>
        <Typography variant='h5'>
          Activity feed
        </Typography>

      </Box>

      <Table
        checkboxSelection={false}
        columns={columns}
        componentsProps={{
          noResultsOverlay: {
            variant: 'time-tracking',
          },
          noRowsOverlay: {
            variant: 'time-tracking',
          },
        }}
        loading={loading}
        paginationModel={{ page: pagination.page, pageSize: pagination.limit }}
        rowCount={hoursTracking.count}
        rows={hoursTracking.data ?? []}
        sx={{
          '& .MuiDataGrid-virtualScroller': {
            pb: 4
          }
        }}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </Stack>
  );
};

export default HoursTrackingTable;
