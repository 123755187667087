import React from 'react';
import {
  Avatar,
  Typography,
  Stack,
  Card,
  CardContent,
  List,
  Box,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { LSActivity } from 'src/components/icons';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useDialog } from 'src/dialogs/Dialogs';
import DisabledBackdrop from 'src/components/v2/DisabledBackdrop';
import { useNavigate } from 'react-router-dom';

const LogsQuery = gql`
  query LogsQuery($studioId: ID!) {
    logs(
      studioId: $studioId
      orderBy: "createdAt"
      sortOrder: "desc"
      limit: 7
    ) {
      id
      createdAt
      user {
        id
        name
        picture
      }
      where
      operation
      model
      after
    }
  }
`;

// Moved to a separate constant for better maintainability
const OPERATION_ACTIONS = {
  create: 'creó',
  update: 'editó',
  delete: 'borró',
};

const MODEL_NAMES = {
  Event: 'un evento',
  Sale: 'un honorario',
  Entity: 'un directorio',
  Document: 'un documento',
  Filecase: 'un expediente',
  Entry: 'una actuación',
  Calendar: 'un calendario',
  State: 'un estado de expediente',
  EntityState: 'un estado de directorio',
};

export function humanizeLog(log) {
  const { model, operation, createdAt } = log;
  const formattedDate = dayjs(createdAt).fromNow();

  return {
    ...log,
    action: OPERATION_ACTIONS[operation] || 'modificó',
    modelName: MODEL_NAMES[model] || 'un elemento',
    formattedDate,
  };
}

const useHandleLogItemClick = (log) => {
  const dispatchFilecaseDialog = useDialog('filecase');
  const dispatchEventDialog = useDialog('event');
  const dispatchEntityDialog = useDialog('entity');
  const dispatchDocumentPreviewDialog = useDialog('documentsPreview');
  const dispatchSaleDialog = useDialog('sale');
  const studioId = useStudioId();
  const navigate = useNavigate();

  const modelHandlers = {
    Event: () =>
      dispatchEventDialog(
        log.where.id ?? log.after.id ?? log.where.googleEventId,
        {
          id: log.where.id ?? log.after.id ?? log.where.googleEventId,
          calendarId: log.where.calendarId ?? log.after.calendarId,
        },
      ),
    Filecase: () =>
      dispatchFilecaseDialog(log.where.id, {
        id: log.where.id,
      }),
    Document: () =>
      dispatchDocumentPreviewDialog(log.where.id, {
        document: log.after,
        downloable: true,
      }),
    Sale: () =>
      dispatchSaleDialog(log.where.id, {
        id: log.where.id,
      }),
    Entity: () => dispatchEntityDialog(log.after.id, { id: log.after.id }),
    Calendar: () => navigate(`/dashboard/${studioId}/calendar`),
  };

  const isClickable =
    !!Object.keys(log.where).length && modelHandlers[log.model];

  const handleClick = () => {
    if (!isClickable) return;
    modelHandlers[log.model]?.();
  };

  return { handleClick, isClickable };
};

export const LogEntry = React.memo(({ log }) => {
  const { handleClick, isClickable } = useHandleLogItemClick(log);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, py: 1 }}>
      <Avatar
        src={log.user.picture}
        alt={log.user.name}
        sx={{ width: 28, height: 28 }}
      />
      <Typography fontWeight="500" fontSize={12} whiteSpace="break-spaces">
        <b>{log.user.name}</b> {log.action}{' '}
        <Box
          component="span"
          sx={{
            fontWeight: '700',
            ...(isClickable && {
              color: 'primary.main',
              textDecoration: 'underline',
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.8,
              },
            }),
          }}
          onClick={isClickable ? handleClick : undefined}
        >
          {log.modelName}
        </Box>{' '}
        {log.formattedDate}
      </Typography>
    </Box>
  );
});

LogEntry.displayName = 'LogEntry';

export const HomeLogs = () => {
  const studioId = useStudioId();
  const { data, loading } = useQuery(LogsQuery, {
    fetchPolicy: 'network-only',
    variables: { studioId },
  });

  const logs = data?.logs ?? [];

  // Hide component when loading for first time or when there are no logs
  if ((loading && !data) || logs.length === 0) {
    return null;
  }

  return (
    <DisabledBackdrop withIndicator in={loading}>
      <Card>
        <CardContent>
          <Stack gap={1}>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              sx={{
                pb: 1,
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <LSActivity color="primary" />
              <Typography variant="h6" fontWeight="600">
                Actividad reciente
              </Typography>
            </Stack>

            <List sx={{ py: 0, maxHeight: 400, overflowY: 'auto' }}>
              {logs.map(humanizeLog).map((log) => (
                <LogEntry key={log.id} log={log} />
              ))}
            </List>
          </Stack>
        </CardContent>
      </Card>
    </DisabledBackdrop>
  );
};
