import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Avatar, Tooltip } from '@mui/material';
import { useGoogleDrive } from 'src/utils/hooks/useGoogleDrive';
import GoogleDriveIcon from 'src/pages/Dashboard/Settings/logo_drive_2020q4_color_2x_web_64dp.png';

const SCOPES = ['https://www.googleapis.com/auth/drive.file'];

export const GoogleDriveButton = ({ disabled, onSelect }) => {
  const {
    getGoogleDriveAccessToken,
    hasGoogleDrive,
    loading: hookLoading,
  } = useGoogleDrive();
  const [loading, setLoading] = React.useState(false);

  const createPicker = (accessToken) => {
    const pickerApi = window.google.picker;

    const folderView = new pickerApi.DocsView(pickerApi.ViewId.FOLDERS);
    folderView.setIncludeFolders(true);
    folderView.setSelectFolderEnabled(true);

    const docsView = new pickerApi.DocsView(pickerApi.ViewId.DOCS);
    docsView.setIncludeFolders(false);
    docsView.setSelectFolderEnabled(false);

    const picker = new pickerApi.PickerBuilder()
      .addView(docsView)
      .addView(folderView)
      .setOAuthToken(accessToken)
      .setDeveloperKey(import.meta.env.VITE_GOOGLE_DEVELOPER_KEY)
      .setLocale('es')
      .setCallback((data) => {
        if (data.action === 'picked') {
          const docs = data.docs.map((doc) => ({
            ...doc,
            kind: 'drive',
            isFolder: doc.mimeType === 'application/vnd.google-apps.folder',
          }));
          onSelect(docs);
        }
        // Reset loading state for both picked and canceled actions
        if (data.action === 'picked' || data.action === 'cancel') {
          setLoading(false);
        }
      })
      .build();

    picker.setVisible(true);
  };

  const initializeGooglePicker = async (accessToken) => {
    // Check if the picker API is loaded
    if (!window.google?.picker) {
      return new Promise((resolve) => {
        window.gapi.load('picker', () => {
          createPicker(accessToken);
          resolve();
        });
      });
    }

    // If picker is already loaded, create the picker directly
    createPicker(accessToken);
  };

  const handleGoogleDrive = async () => {
    setLoading(true);

    // First try to get the token from the hook (refresh token flow)
    const existingToken = await getGoogleDriveAccessToken();

    if (existingToken) {
      await initializeGooglePicker(existingToken);
      return;
    }

    // If no token is available, proceed with OAuth2 flow
    const tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      scope: SCOPES.join(' '),
      error_callback: () => setLoading(false),
      callback: async (response) => {
        if (response.error) {
          setLoading(false);
          console.error('Error getting OAuth2 token:', response.error);
          return;
        }

        await initializeGooglePicker(response.access_token);
      },
    });

    tokenClient.requestAccessToken();
  };

  const isLoading = loading || hookLoading;

  return (
    <Tooltip
      disableFocusListener={isLoading || disabled || !hasGoogleDrive}
      disableHoverListener={isLoading || disabled || !hasGoogleDrive}
      disableTouchListener={isLoading || disabled || !hasGoogleDrive}
      title='Dirigete a "configuración de estudio" 👉 "Google Drive" para trabajar con tus archivos rapidamente'
    >
      <span>
        <LoadingButton
          loading={isLoading}
          startIcon={<Avatar src={GoogleDriveIcon} />}
          sx={{
            textTransform: 'none',
            mt: 2,
            p: 2,
          }}
          variant="text"
          onClick={handleGoogleDrive}
        >
          Google Drive
        </LoadingButton>
      </span>
    </Tooltip>
  );
};
